/**
 * Different known platform types
 */
export enum Platform {
  /** Mobile App Platform inside Cordova on iOS */
  iOSCordova,
  /** Mobile App Platform inside NetspendSDK on iOS */
  iOSNetspendSdk,
  /** Mobile App Platform inside Cordova on Android */
  AndroidCordova,
  /** Mobile App Platform inside NetspendSDK on Android */
  AndroidNetspendSdk,
  /** Not a mobile app platform, inside a vanilla browser */
  Web
}

/**
 * Get the current Platform based on the user agent string
 */
export const getCurrentPlatform = (
  userAgent = navigator.userAgent
): Platform => {
  const ua = userAgent.toLowerCase();
  switch (true) {
    case ua.includes('cordova/ios'):
      return Platform.iOSCordova;
    case ua.includes('netspendsdk/ios'):
      return Platform.iOSNetspendSdk;
    case ua.includes('cordova/android'):
      return Platform.AndroidCordova;
    case ua.includes('netspendsdk/android'):
      return Platform.AndroidNetspendSdk;
    default:
      return Platform.Web;
  }
};

/**
 * Use this to determine if running inside an Android Mobile App Context
 *
 * Note: This does not detect chrome/browsers on android
 */
export const isAndroid = (platform: Platform = getCurrentPlatform()): boolean =>
  [Platform.AndroidCordova, Platform.AndroidNetspendSdk].includes(platform);

/**
 * Use this to determine if running inside an iOS Mobile App Context
 *
 * Note: This does not detect safari/browsers on iOS
 */
export const isIOS = (platform: Platform = getCurrentPlatform()): boolean =>
  [Platform.iOSCordova, Platform.iOSNetspendSdk].includes(platform);

/**
 * Use this to determine if running inside a browser, not inside a mobile app
 *
 * Note: This does includes browser on iOS or Android devices, not just desktop
 */
export const isWeb = (platform: Platform = getCurrentPlatform()): boolean =>
  [Platform.Web].includes(platform);

/**
 * Use this to determine if running inside a cordova
 */
export const isCordova = () =>
  [Platform.AndroidCordova, Platform.iOSCordova].includes(getCurrentPlatform());
